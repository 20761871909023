import React, { useCallback, useMemo, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import { FontWeightProperty, TextAlignProperty, TextTransformProperty } from 'csstype';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '64px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const fileNameWrapperStyle = {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    marginTop: '8px'
};

const buttonWrapperStyle = {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    gap: '8px',
    marginTop: '8px'
};

const textButtonStyle = {
    fontStyle: 'normal',
    fontWeight: 'bold' as FontWeightProperty,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center' as TextAlignProperty,
    letterSpacing: '0.08em',
    textTransform: 'uppercase' as TextTransformProperty
};

const buttonStyle = {
    height: '32px',
    width: '120px'
};

type Props = {
    maxFiles?: number;
    maxSize?: number;
    multiple?: boolean;
    showRemoveFileButton?: boolean;
    showRemoveAllFilesButton?: boolean;
    isOpen: boolean;
    onConfirm: (files: File[] | null, removeAllFiles: () => void) => void;
    onCancel: () => void;
};

const formatBytes = (bytes: number, decimals = 2) => {
    if (!bytes) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const FileUploadDialog = ({
    maxFiles = 1,
    maxSize = 10485760,
    multiple = false,
    showRemoveFileButton = true,
    showRemoveAllFilesButton = false,
    isOpen,
    onConfirm,
    onCancel
}: Props) => {
    const [acceptedFiles, setAcceptedFiles] = useState<File[] | null>(null);
    const [rejectedFiles, setRejectedFiles] = useState<FileRejection[] | null>(null);

    const onDrop = useCallback((files: File[], fileRejections: FileRejection[]) => {
        setAcceptedFiles(files);
        setRejectedFiles(fileRejections);
    }, []);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        maxFiles,
        maxSize,
        multiple
    });

    const style = useMemo(
        () =>
            ({
                ...baseStyle,
                ...(isFocused ? focusedStyle : {}),
                ...(isDragAccept ? acceptStyle : {}),
                ...(isDragReject ? rejectStyle : {})
            } as React.CSSProperties),
        [isFocused, isDragAccept, isDragReject]
    );

    const removeAcceptedFile = useCallback((name: string) => {
        setAcceptedFiles(prevState => (prevState?.length ? prevState?.filter(file => file.name !== name) : null));
    }, []);

    const removeAllFiles = useCallback(() => {
        setAcceptedFiles(null);
        setRejectedFiles(null);
    }, []);

    const onUploadClick = useCallback(() => {
        onConfirm(acceptedFiles, removeAllFiles);
    }, [acceptedFiles, onConfirm]);

    const onCancelClick = useCallback(() => {
        setRejectedFiles(null);
        onCancel();
    }, [onCancel]);

    return (
        <Modal isOpen={isOpen} onClosed={onCancelClick} toggle={onCancelClick}>
            <ModalHeader toggle={onCancelClick}>Upload File</ModalHeader>

            <ModalBody>
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>Drag &apos;n&apos; drop some file here, or click to select file</p>
                    <p>The maximum file size is {formatBytes(maxSize)}. Formats: any</p>
                </div>

                {Boolean(acceptedFiles?.length) && (
                    <div style={{ marginTop: '8px' }}>
                        {acceptedFiles?.map(file => (
                            <div key={file.name} style={fileNameWrapperStyle}>
                                <p>
                                    {file.name} - {formatBytes(file.size)}
                                </p>
                                {showRemoveFileButton && (
                                    <Button style={buttonStyle} onClick={() => removeAcceptedFile(file.name)}>
                                        Delete
                                    </Button>
                                )}
                            </div>
                        ))}
                    </div>
                )}

                {Boolean(rejectedFiles?.length) && (
                    <div style={{ marginTop: '8px' }}>
                        {rejectedFiles?.map(({ file, errors }) => (
                            <React.Fragment key={file.name}>
                                <div style={fileNameWrapperStyle}>
                                    <p style={{ color: rejectStyle.borderColor }}>
                                        {file.name} - {formatBytes(file.size)}
                                    </p>
                                </div>
                                <ul style={{ margin: '0', color: rejectStyle.borderColor, fontSize: '12px' }}>
                                    {errors.map(e => (
                                        <li key={e.code}>{e.message}</li>
                                    ))}
                                </ul>
                            </React.Fragment>
                        ))}
                    </div>
                )}

                {showRemoveAllFilesButton && (acceptedFiles?.length || rejectedFiles?.length) && (
                    <div style={fileNameWrapperStyle}>
                        <Button onClick={removeAllFiles}>Delete all</Button>
                    </div>
                )}
            </ModalBody>

            <ModalFooter>
                <div style={buttonWrapperStyle}>
                    <Button style={buttonStyle} onClick={onCancelClick}>
                        <p style={textButtonStyle}>Cancel</p>
                    </Button>
                    <Button style={buttonStyle} onClick={onUploadClick} disabled={!acceptedFiles?.length || Boolean(rejectedFiles?.length)}>
                        <p style={textButtonStyle}>Upload</p>
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};
