import { AttributeTextValue, AttributeValueBase } from '@msdyn365-commerce/retail-proxy';

export const createTextAttribute = (name: string, value: string) => ({
    Name: name,
    TextValue: value,
    TextValueTranslations: [],
    '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue'
});

export const updateTextAttribute = (attribute: AttributeTextValue, value: string) => {
    if (attribute) {
        attribute.TextValue = value;
        attribute.TextValueTranslations = [];
        attribute['@odata.type'] = '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue';
    }

    return attribute;
};

export const getAttributeByName = (attributeName: string, attributes: AttributeValueBase[]): AttributeValueBase | undefined => {
    const attribute = attributes?.find(({ Name }) => Name === attributeName);

    return attribute;
};

export const getAttributeTextValueByName = (attributeName: string, attributes: AttributeValueBase[]): string => {
    const attribute = getAttributeByName(attributeName, attributes);
    const value = (attribute as AttributeTextValue)?.TextValue;

    return value ?? '';
};
