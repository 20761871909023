export interface IImageResponse {
    imageUrl: string;
    errorMessage?: string;
}

export class ImageResponseClass implements IImageResponse {
    public imageUrl: string;
    public errorMessage?: string;

    constructor(odataObject?: any) {
        odataObject = odataObject || {};

        this.imageUrl = odataObject.imageUrl;
        this.errorMessage = odataObject.errorMessage;
    }
}

export interface IProductTimeSlot {
    RecId: number;
    TimeSlotTxt: string;
}

export class ProductTimeSlotsResponse implements IProductTimeSlot {
    public RecId: number;
    public TimeSlotTxt: string;

    constructor(odataObject?: any) {
        odataObject = odataObject || {};

        this.RecId = odataObject.RecId;
        this.TimeSlotTxt = odataObject.TimeSlotTxt;
    }
}
